import React from 'react';
import '../styles/globals.css';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';
import { BlueColor } from '../config';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <title>AltHire AI</title>

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap"
        rel="stylesheet" />

      <DefaultSeo
        title="AltHire AI"
        titleTemplate="%s"
        defaultTitle="AltHire AI | Experience the Future of Hiring with AI-Powered Interviews"
        description="AltHire AI is an AI-powered interview platform designed to conduct unbiased and objective interviews. By leveraging advanced AI algorithms, we simulate real interview scenarios to assess candidates’ skills, qualifications, and fit for a role without human bias."
        // openGraph={{
        //   url: 'https://meet.livekit.io',
        //   images: [
        //     {
        //       url: 'https://meet.livekit.io/images/livekit-meet-open-graph.png',
        //       width: 2000,
        //       height: 1000,
        //       type: 'image/png',
        //     },
        //   ],
        //   site_name: 'LiveKit Meet',
        // }}
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: BlueColor,
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico',
          },
          {
            rel: 'apple-touch-icon',
            href: '/images/althireai-apple-touch.png',
            sizes: '180x180',
          },
          {
            rel: 'mask-icon',
            href: '/images/althireai-safari-pinned-tab.svg',
            color: BlueColor,
          },
        ]}
      />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
